import axios from "axios";

const axiosInstance = axios.create({
    baseURL: 'https://apa-api.sagtechs.com/api/',
});

function Interceptors(axiosInstance) {

    const Lang = localStorage.getItem("i18nextLng");

    axiosInstance.defaults.headers.common['locale'] = Lang;

    // Request interceptor
    axiosInstance.interceptors.request.use(function (config) {
        // Before request is sent
        return config;
        }, function (error) {
            // Request error
            console.log("connection error");
    });

    // Response interceptor
    axiosInstance.interceptors.response.use(function (response) {
        // handle response data
        return response;

    }, function (error) {

        // handle response error cases
        if (error.response) {

            // The request was made and the server responded with a status code
            switch (error.response.status) {

                case 401:
                    // Unauthorized user
                    break;

                case 403:
                    window.location = "/error/403";
                    break;

                case 404:
                    window.location = "/error/404";
                    break;

                case 410:
                    window.localStorage.clear();
                    window.location = "/login";
                    break;

                case 500:
                    window.location = "/error/500";
                    break;
            
                default:
                    return new Promise((resolve, reject) => {
                        reject(error);
                    });
            }

        } else if (error.request) {

            // The request was made but no response was received
            console.log(error.request);
            console.log("something went wrong!");
            console.log("check network");

        } else {

            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }

    });

}

export default function Axios () {

    Interceptors(axiosInstance);
    return axiosInstance;

}

export function AxiosPrivate () {

    const AUTH_TOKEN = localStorage.getItem("access_token") || '';

    // Config authorization token 
    if (AUTH_TOKEN) {
        axiosInstance.defaults.headers.common['Authorization'] = 
        `Bearer ${AUTH_TOKEN}`;
    } else {
        window.location = "/login";
    }

    Interceptors(axiosInstance);

    return axiosInstance;
}