import React, { Suspense, lazy, useMemo, useState, createContext } from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import {
  Routes,
  Route,
  Navigate
} from 'react-router-dom';

// pages
import Home from 'pages/Home';

// components
import LoadIcon from 'components/LoadIcon';
import Header from 'components/Header';
import ProtectedRoute from 'components/ProtectedRoute';
import AuthProvider from 'context/AuthProvider';


// lazy importing
const News = lazy(() => import('pages/News'));
const NewsDetails = lazy(() => import('pages/NewsDetails'));
const Library = lazy(() => import('pages/Library'));
const Category = lazy(() => import('pages/Category'));
const Links = lazy(() => import('pages/Links'));
const About = lazy(() => import('pages/About'));
const Contact = lazy(() => import('pages/Contact'));
const Login = lazy(() => import('pages/Login'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const ResetPass = lazy(() => import('pages/ResetPass'));
const Error = lazy(() => import('components/Error'));
const Footer = lazy(() => import('components/Footer'));

export const LoadContext = createContext();

function App() {

  const { t } = useTranslation('consent');

  const isAuthenticated = localStorage.getItem("isAuthenticated");

  const [isLoading, setIsLoading] = useState(false);

  const value = useMemo(() => ({ isLoading, setIsLoading }), [isLoading, setIsLoading]);

  React.useEffect(() => {

    const Lang = localStorage.getItem('i18nextLng');
    document.documentElement.lang = Lang || 'en';
    document.documentElement.dir = Lang === 'ar' ? 'rtl' : 'ltr';

  }, []);

  return (

    <AuthProvider>
      <LoadContext.Provider value={value}>

        <Header />

        <div className='content-wrapper'>

          <Suspense fallback={<LoadIcon />}>

          {isLoading && <LoadIcon />}

          <Routes>

            <Route path='' element={<Home />} />

            <Route path='login' element={isAuthenticated ? <Navigate to="/" /> : <Login />} />

            <Route element={<ProtectedRoute allowedRole="admin" />}>
              <Route path='admin/*' element={<Dashboard />} />
            </Route>        

            <Route element={<ProtectedRoute allowedRole="agent" />}>
              <Route path='agent/*' element={<Dashboard />} />
            </Route>    

            <Route element={<ProtectedRoute allowedRole="agent" />}>
              <Route path='reset-password' element={<ResetPass />} />
            </Route>      

            <Route path='links' element={<Links />} />
            <Route path='library' element={<Library />} />
            <Route path='library/:id' element={<Category />} />
            <Route path='news' element={<News />} />
            <Route path='news/:id' element={<NewsDetails />} />
            <Route path='about' element={<About/>} />
            <Route path='contact' element={<Contact />} />
            <Route path='error/:code' element={<Error />} />
            <Route path='*' element={<Error />} />
          </Routes>


        </Suspense>
        </div>
        <Footer />

        <CookieConsent
          buttonText={t('IU')}
          containerClasses='justify-content-center cookies-container'
          contentClasses='m-0 cookies-content text-center'
          buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        >
          {t('consentText')}
        </CookieConsent>

        </LoadContext.Provider>
      </AuthProvider>
  );
}

export default App;
