import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

function ProtectedRoute({ allowedRole }) {
  const location = useLocation();

  const role = JSON.parse(localStorage.getItem('user'))?.role || 'guest';

  return allowedRole === role ? <Outlet /> : <Navigate to="/login" state={{ from: location }} />

  // Outlet > you have access
}

export default ProtectedRoute;