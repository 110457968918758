import React, { useContext, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { DropdownButton, Nav, Navbar } from 'react-bootstrap';
import logo from 'assets/media/logo.png';
import LanguageSwitcher from 'components/LanguageSwitcher';
import { AuthContext } from 'context/AuthProvider';
import { LoadContext } from 'App';
import { AxiosPrivate } from 'services/axiosInstance';
import Search from 'components/Search';
import SearchCard from 'components/Cards/Search';

//import { Toast } from 'primereact/toast';
import axios from 'axios';

function Header() {

    const toast = React.useRef(null);

    const { t } = useTranslation("general");
    const [newsData, setNewsData] = React.useState([]);
    const [eventData, setEventData] = React.useState([]);
    const [libraryData, setLibraryData] = React.useState([]);

    const location = useLocation().pathname;

    const isAuthenticated = localStorage.getItem("isAuthenticated");
    const isLg = useMediaQuery({ query: `(min-width: 992px)` });
    const { auth } = useContext(AuthContext);
    const { setIsLoading } = React.useContext(LoadContext);
    const [searchVal, setSearchVal] = useState('');
    const [catsList, setCatsList] = useState([]);
    const [total, setTotal] = React.useState(0);
    const [changer, setChanger] = useState(false);
    const isCustom = !isAuthenticated || (!location.match(/admin/) && !location.match(/agent/));

    function handleChange(e) {
        setSearchVal(e?.target?.value);
        !e?.target?.value
            &&
            setChanger(!changer);
        if (e?.target?.value.length == 0) {
            catsList.length = 0;
        }
    }
    function handleSearch() {
        if (searchVal) {
            setIsLoading(true);
            AxiosPrivate().get(`search?search=${searchVal}`)
                .then((res) => {
                    const newsData = res.data.data.news.collection;
                    const eventData = res.data.data.event.collection;
                    const libraryData = res.data.data.library.collection;
                    const allData = [...newsData, ...eventData, ...libraryData];
                    setNewsData(newsData);
                    setEventData(eventData);
                    setLibraryData(libraryData);
                    setCatsList(allData);
                    console.log("Youssef", catsList);
                    setTotal(res.data.data.pageInfo.total);
                    setIsLoading(false);
                    document.querySelector(".p-paginator-pages .p-paginator-page")?.click();

                }).catch(() => {
                    setIsLoading(false);
                })
        }
    }
    function handleDownload(url) {
        // const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', "_blank"); //or any other extension
        document.body.appendChild(link);
        link.click();
    }



    const NavCollapse = () => {
        return (


            <Navbar.Collapse id="responsive-navbar-nav">
                {/* <Search handleChange={handleChange} handleSearch={handleSearch} /> */}
                <div className=" pager-container " >
                    {
                        catsList?.length > 0 ?
                            <div className="cards-wrapper row " style={{ width: '75%', marginLeft: '15%', marginTop: '2%', marginRight: '15%' }}>
                                {
                                    catsList
                                        .map((card) => {
                                            if (newsData.length > 0) {
                                                return (
                                                    <div className="  mb-4" onClick={() => handleDownload(card.description)}>
                                                        <SearchCard img={card.image} title={card.title} date={card.date} />
                                                    </div>
                                                );
                                            }
                                            if (eventData.length > 0) {
                                                return (
                                                    <div className=" mb-4" onClick={() => handleDownload(card.description)} >
                                                        <SearchCard img={card.image} title={card.title} date={card.date} />
                                                    </div>
                                                );
                                            }
                                            if (libraryData.length > 0) {
                                                return (
                                                    <div className=" mb-4" >
                                                        <NavLink to={`/library/${card.id}`}>
                                                            <SearchCard img={card.image} title={card.title} />
                                                        </NavLink>
                                                    </div>
                                                );
                                            }
                                        })
                                }
                            </div>
                            : ''}
                </div>

                <Nav className="ms-auto align-items-center">

                    <NavLink to="/">{t("home")}</NavLink>
                    <NavLink to="/news">{t("news")}</NavLink>
                    <NavLink to="/library">{t("library")}</NavLink>
                    <NavLink to="/links">{t("links")}</NavLink>
                    <div className="dropdown">
                        <NavLink to="/about">{t("about")} </NavLink>
                        <div className="dropdown-content">
                            <a href="/about#africa">{t("about")}</a>
                            <a href="/about#history" >{t("history")}</a>
                            <a href="/about#objective">{t("objective")}</a>
                            <a href="/about#organs">{t("organs")}</a>
                            <a href="/about#ours">{t("vision & mission")}</a>
                            <a href="/about#members">{t("countries")}</a>
                        </div>
                    </div>

                    <NavLink to="/contact">{t("contact")}</NavLink>

                    <div className="header-options d-flex button-lang">
                        <NavLink to="/login">{t("login")}</NavLink>
                    </div>
                    <div className="header-options d-flex button-en">
                        <LanguageSwitcher />
                    </div>


                </Nav>
                {/* <div className=" button-en">
                        <LanguageSwitcher/>
                </div> */}
                <div className="containers-fluid first-nav " >
                    <div className="header-options d-flex w-100">
                        <div className="soc-links">
                            <a target="_blank" rel="noreferrer" href="https://twitter.com/apaafrican?s=11&t=meiEJB7XH7aSBHBYJ6_N4Q">
                                <i className='fab fab fa-twitter' />
                            </a>
                        </div>
                        {
                            auth?.role === "agent"
                                ?
                                <button type='button' onClick={handleForget} className="forget-btn reset-button mb-0 white">
                                    {t('forget')}
                                </button>
                                :
                                ''
                        }


                    </div>
                </div>
            </Navbar.Collapse>
        );
    }
    const loggedNav = () => {
        return (
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ms-auto align-items-center">

                    {
                        !(location.match(/admin/) || location.match(/agent/))
                            ?
                            <div className="button-lang header-options d-flex"><NavLink to={auth?.role === "admin" ? "/admin/content/home" : "/agent/links"}>{t("dashboard")}</NavLink></div>
                            :
                            <span className='welcome-msg'>
                                {t("welcome")}&nbsp;{" "}
                                {auth?.role === "admin"
                                    ?
                                    <b>admin</b>
                                    :
                                    <b>{auth?.name?.split(" ")[0]}</b>
                                }
                            </span>
                    }

                </Nav>
            </Navbar.Collapse >
        );
    }

    const responsiveNav = () => {

        return (

            <Navbar.Collapse id="responsive-navbar-nav">

                {
                    auth?.role === 'admin'
                        ?
                        <Nav className="ms-auto align-items-center">
                            <NavLink to="/admin/content">{t("content")}</NavLink>
                            <NavLink to="/admin/library">{t("library")}</NavLink>
                            <NavLink to="/admin/links">{t("links")}</NavLink>
                            <NavLink to="/admin/chat">{t("messages")}</NavLink>
                            <NavLink to="/admin/users">{t("users")}</NavLink>
                            <NavLink to="/admin/news">{t("news")}</NavLink>
                            <li className='d-flex' onClick={logout}><NavLink to="#">{t("logout")}</NavLink></li>
                        </Nav>
                        :
                        <Nav className="ms-auto align-items-center">
                            <NavLink to="/agent/library">{t("library")}</NavLink>
                            <NavLink to="/agent/links">{t("links")}</NavLink>
                            <NavLink to="/agent/chat">{t("messages")}</NavLink>
                            <li className='d-flex' onClick={logout}><NavLink to="#">{t("logout")}</NavLink></li>
                        </Nav>
                }
                <LanguageSwitcher />


            </Navbar.Collapse>

        );


    }

    function linksFilter() {
        return (
            isAuthenticated
                ?
                isLg
                    ?
                    loggedNav()
                    :
                    responsiveNav()
                :
                NavCollapse()
        )
    }

    function logout() {
        setIsLoading(true);
        AxiosPrivate().post('logout', {})
            .then(() => {

                localStorage.clear();
                window.location = '/login';

            }).catch(() => {
                setIsLoading(false);
            })
    }

    // window.onscroll = function() {myFunction()};

    // var header = document.getElementById("headerid");
    // var sticky = header.offsetTop;

    // function myFunction() {
    //   if (window.pageYOffset > sticky) {
    //     header.classList.add("sticky");
    //   } else {
    //     header.classList.remove("sticky");
    //   }
    // }

    function handleForget() {
        setIsLoading(true);
        axios.post('https://apa-api.sagtechs.com/api/password/send', { email: auth?.email })
            .then(() => {
                setIsLoading(false);
                toast.current.show({ severity: 'success', detail: t('forgetmsg') })
            })
            .catch((err) => {
                setIsLoading(false);
                err?.response?.data?.errors
                    &&
                    toast.current.show({ severity: 'warn', detail: Object.values(err.response.data.errors) })
            })
    }

    return (
        <div className={isCustom ? 'custom-navs' : ''} >
            <Navbar expand="lg" bg="dark" className="second-nav py-md-2 py-1" variant={isCustom ? "dark" : 'light'}>
                <div className="container-fluid" id='headerid' >
                    <Navbar.Brand href="/">
                        <img
                            src={logo}
                            width="75"
                            height="70"
                            className="d-inline-block align-top"
                            alt="Apa logo"
                        />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    {linksFilter()}

                </div>
            </Navbar>
        </div>
    )


}


export default Header