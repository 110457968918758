import React, { lazy, Suspense, useEffect, useState } from 'react';

// components
import { LocalLoadIcon } from 'components/LoadIcon';
import Hero from 'components/Hero';
import Search from 'components/Search';
import Pager from 'components/Pager';
import { useTranslation } from 'react-i18next';


import Axios from 'services/axiosInstance';
import { LoadContext } from 'App';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import NewsCard from 'components/Cards/News';
import SearchCard from 'components/Cards/Search';
import LibCard from 'components/Cards/Library';

// lazy importing
const Message = lazy(() => import('components/Home/Message'));
const Ours = lazy(() => import('components/Home/Ours'));
const Map = lazy(() => import('components/Home/Map'));
const Chairs = lazy(() => import('components/Home/Chairs'));
const News = lazy(() => import('components/Home/News'));
const Testimonials = lazy(() => import('components/Home/Testimonials'));


function Home() {
    const { t } = useTranslation('general');
    const { setIsLoading } = React.useContext(LoadContext);
    const [data, setData] = React.useState('');
    const [newsData, setNewsData] = React.useState([]);
    const [eventData, setEventData] = React.useState([]);
    const [libraryData, setLibraryData] = React.useState([]);
    const lang = localStorage.getItem("i18nextLng");
    const isLg = useMediaQuery({ query: `(min-width: 992px)` });
    const [changer, setChanger] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [displayBasic, setDisplayBasic] = useState(false);
    // const { setIsLoading } = React.useContext(LoadContext);
    const [total, setTotal] = React.useState(0);
    //  const [catsList, setCatsList] = useState<[]>([newsData,eventData,libraryData]);
    const [catsList, setCatsList] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        Axios().get('page/home').then((res) => {
            setData(res.data.data.sections);
            localStorage.setItem('cover', res.data.data.sections?.home_cover?.home_cover_image)
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        })
    }, [lang]);  // eslint-disable-line react-hooks/exhaustive-deps
    function handleChange(e) {
        setSearchVal(e?.target?.value);
        !e?.target?.value
            &&
            setChanger(!changer);
        if (e?.target?.value.length == 0) {
            catsList.length = 0;
        }
    }

    function handleSearch() {
        if (searchVal) {
            setIsLoading(true);
            Axios().get(`search?search=${searchVal}`)
                .then((res) => {
                    const newsData = res.data.data.news.collection;
                    const eventData = res.data.data.event.collection;
                    const libraryData = res.data.data.library.collection;
                    const allData = [...newsData, ...eventData, ...libraryData];
                    setNewsData(newsData);
                    setEventData(eventData);
                    setLibraryData(libraryData);
                    setCatsList(allData);
                    // console.log("Youssef", catsList);
                    setTotal(res.data.data.pageInfo.total);
                    setIsLoading(false);
                    document.querySelector(".p-paginator-pages .p-paginator-page")?.click();

                }).catch(() => {
                    setIsLoading(false);
                })
        }
    }
    function handleDownload(url) {
        // const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', "_blank"); //or any other extension
        document.body.appendChild(link);
        link.click();
    }


    return (
        <div className='homepage'>
            <Search handleChange={handleChange} handleSearch={handleSearch} />
            <Hero unique={true} background={data?.home_cover?.home_cover_image} head={data?.home_cover?.cover_image_text || ''} />
            

            <div className=" pager-container " >
                {
                    catsList?.length > 0 ?
                        <div className="cards-wrapper row " style={{width:'75%',marginLeft:'15%',marginTop:'2%',marginRight:'15%'}}>
                            {
                                catsList
                                    .map((card) => {                                     
                                    if (newsData.length > 0) {
                                            return (                                              
                                                <div className="  mb-4" onClick={() => handleDownload(card.description)}>                                                 
                                                    <SearchCard img={card.image} title={card.title} date={card.date} />                                                   
                                                </div>
                                            );
                                        }
                                        if (eventData.length > 0) {
                                            return (
                                                <div className=" mb-4"onClick={() => handleDownload(card.description)} >                                                  
                                                    <SearchCard img={card.image} title={card.title} date={card.date} />
                                                </div>
                                            );
                                        }
                                        if (libraryData.length > 0) {
                                            return (
                                                <div className=" mb-4" >
                                                    <NavLink to={`/library/${card.id}`}>                                                
                                                        <SearchCard img={card.image} title={card.title}  />
                                                    </NavLink>
                                                </div>
                                            );
                                        }
                                    })
                            }
                        </div>
                        : <Suspense fallback={<LocalLoadIcon />} >
                            <Message data={data?.apa_message} />
                            <Chairs data={data?.chairs} />
                            <Map data={data?.map} />
                            <div className='bg-size' >
                                <News />
                                <Testimonials data={data?.testimonials} />
                            </div>
                        </Suspense>}
            </div>   
        </div>
    )
}

export default Home