import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import imgSearch from 'assets/media/icons8-search-30.png'


function Search({ handleChange, handleSearch }) {

    const { t } = useTranslation('general');

    return (
<input type="text" name="search" placeholder="Search.." onClick={handleSearch} onChange={handleChange} style={{'backgroundImage': `url(${imgSearch})` }}>
</input>



        // <div className="p-inputgroup search-set mb-4">
        //     <InputText className='input-style' onChange={handleChange} placeholder={t('search')} type='text'/>
            
        //     <Button icon="pi pi-search" className="p-button-secondary" onClick={handleSearch} >
        //         {/* <a href='./allsearch'></a> */}
        //         </Button>
               
        // </div>
    )
}


export default Search