import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false
    },
    fallbackLng: localStorage.getItem("i18nextLng") || 'en',
    debug: false,
    lng: localStorage.getItem("i18nextLng") || 'en',
  });


export default i18n;
