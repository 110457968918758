import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { AxiosPrivate } from 'services/axiosInstance';

function LanguageSwitcher() {

    const { i18n } = useTranslation();
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    const Lang = localStorage.getItem('i18nextLng');
    const [selectedLang, setSelectedLang] = React.useState(Lang || 'en');

    function changeLanguage(e) {
        setSelectedLang(e.value.code);
        i18n.changeLanguage(e.value.code);
        document.documentElement.lang = e.value.code;
        document.documentElement.dir = e.value.code !== 'ar' ? 'ltr' : 'rtl';
        isAuthenticated
            &&
            AxiosPrivate().post(`locale-change/${e?.value?.code || 'en'}`, {})
    };

    const Langs = [
        { name: 'English', code: 'en' },
        { name: 'العربية', code: 'ar' },
        { name: 'French', code: 'fr' }
    ];

    const placeGenerator = () => {

        let temp;

        switch (selectedLang) {
            case 'en':
                temp = 'En'
                break;

            case 'ar':
                temp = 'العربية'
                break;

            case 'fr':
                temp = 'Fr'
                break;

            default:
                temp = 'En'
                break;
        }

        return temp;
    }

    return (
        <Dropdown panelClassName='header-panel' value={selectedLang} options={Langs} onChange={changeLanguage} optionLabel='name' placeholder={placeGenerator()} />
    )
}

export default LanguageSwitcher;