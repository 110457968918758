import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';


function LibCard({ img, title }) {

    return (
        <Card>
            <Card.Img variant="top" src={img} />
            <Card.Body>
                <div className="card-details">
                    <Card.Title>{title}</Card.Title>
                </div>
            </Card.Body>
        </Card>
    )
}

LibCard.propTypes = {
    img: PropTypes.string,
    title: PropTypes.string
};


export default LibCard