export function DateGenerator(myDate, type) {

    const lang = localStorage.getItem("i18nextLng") || 'en';

    let UTC = new Date(myDate);
    let mins = UTC.getMinutes();
    let day = UTC?.getDate();
    let month = UTC.toLocaleString(lang, { month: 'short' });
    let year = UTC.getUTCFullYear();

    let newDate = `${day} ${month} ${year}`;
    let hourDate = `${UTC.getHours()}:${mins < 10 ? '0' : ''}${mins}`;

    return type === 'hour' ? hourDate : newDate;
}