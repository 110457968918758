import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'services/axiosInstance';
import { useTranslation } from 'react-i18next';
import { LoadContext } from 'App';
import Search from 'components/Search';
 //import { NavLink, useLocation } from 'react-router-dom';
 
const Hero = ({ head, unique, background }) => {
    

    const [cover, setCover] = useState('');
    const { t } = useTranslation("general");
    const [changer, setChanger] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const { setIsLoading } = React.useContext(LoadContext);
    const [total, setTotal] = React.useState(0);
    const [catsList, setCatsList] = useState([]);
    React.useEffect(() => {
        if (!unique) {
            let temp = localStorage.getItem('cover');
            if (temp) {
                setCover(temp);
            } else {
                Axios().get('page/home').then((res) => {
                    setCover(res?.data?.data?.sections?.home_cover?.home_cover_image);
                    localStorage.setItem('cover', res.data.data.sections?.home_cover?.home_cover_image)
                })
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    // function handleChange(e) {
    //     setSearchVal(e?.target?.value);
    //     !e?.target?.value
    //         &&
    //         setChanger(!changer);
    // }
    // function handleSearch() {
    //     if (searchVal) {
    //         setIsLoading(true);
    //         Axios().get(`search=${searchVal}`)
    //             .then((res) => {
    //                 const data = res.data.data.collection;
    //                 setCatsList(data);
    //                 setTotal(res.data.data.pageInfo.total);
    //                 setIsLoading(false);
    //                 document.querySelector()?.click();

    //             }).catch(() => {
    //                 setIsLoading(false);
    //             })
    //     }
    // }

    return (
        <section className='hero-section bg-size' style={{ 'backgroundImage': `url(${unique ? background : cover})` }}>
             {/* <Search handleChange={handleChange} handleSearch={handleSearch} /> */}
            <div className="container-fluid">
                <h1 className='text-center max-text'>{head}</h1>
            
            </div>
    
        </section>
    );
};


Hero.propTypes = {
    head: PropTypes.string,
    background: PropTypes.string
};


export default Hero;
