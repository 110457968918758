import { createContext, useState, useMemo, useEffect, useCallback } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        user
            &&
            setAuth(user)
    }, []);

    const updateAuth = useCallback((e) => {
        localStorage.setItem('user', JSON.stringify(e));
    }, []);

    const value = useMemo(() => ({ auth, updateAuth }), [auth, updateAuth]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider; 