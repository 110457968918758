import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DateGenerator } from 'helpers/DateGenerator';
import example from 'assets/media/example.png';

function SearchCard({ img, title, role}) {

    const { t } = useTranslation('general');

    return (
        <Card style={{ width: '20rem', margin: "auto",height:'29rem',marginLeft:'50px',color:'#404040'}}>
            <Card.Img variant="top" src={img || example} style={{height:'15rem',borderRadius:'6px'}}/>
            <Card.Body>
                <div className="card-details">
                    <Card.Title >{title}</Card.Title >
                </div>
            </Card.Body>
        </Card>
    )
}

SearchCard.propTypes = {
    img: PropTypes.string,
    title: PropTypes.string
};


export default SearchCard