export function LocalLoadIcon() {
    return (
        <div className='spinner-grow m-4' style={{ 'width': '2rem', 'height': '2rem' }} role='status'>
            <span className='sr-only'>Loading...</span>
        </div>
    )
}
export default function LoadIcon() {
    return (
        <div className='text-end spinner-container p-4'>
            <div className='spinner-grow' style={{ 'width': '2rem', 'height': '2rem' }} role='status'>
                <span className='sr-only'>Loading...</span>
            </div>
        </div>
    )
}
